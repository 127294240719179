import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import Select from "react-select";
import {Link} from "react-router-dom";
import DataTable from "../../components/tables/DataTable";
import Loader from "../../components/loaders/Loader";
import Pagination from "../../components/Pagination";
import AffiliateNetworkOffersTableFilter from "../../components/AffiliateNetworkOffersTableFilter";
import MessageSendingData from "../../components/popups/MessageSendingData";
import prepareCSVData from "../../helpers/prepare-csv-data";
import {getCurrentDateFormatted} from "../../helpers/get-current-date-formatted";
import ChangedCampaignsTabs from "../../components/ChangedCampaignsTabs";
import {campaignsDownloadExcel, getCampaigns} from "../../services/change-campaigns";
import {
    orderDirectionOptions,
    rowsLimitOptions,
} from "./table-options";
import "./changed-campaign.scss";
import {logOut} from "../../redux/dataUploader/dataSlice";
import {useDispatch} from "react-redux";

const ChangedCampaignsList = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [restOffersData, setRestOffersData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const [rowsLimit, setRowsLimit] = useState({value: "10", label: "10 / page"});
    const [totalRows, setTotalRows] = useState(0);
    const [totalPagesCount, setTotalPagesCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedFilters, setSelectedFilters] = useState({
        orderDirection: orderDirectionOptions[0],
        searchInColumns: {},
    });
    const [orderOptions, setOrderOptions] = useState([]);
    const [selectedUser, setSelectedUser] = useState({
        id: localStorage.getItem("id"),
        name: localStorage.getItem("name"),
    })
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false)
    const [confirmationPopupText, setConfirmationPopupText] = useState("")
    const [usersList, setUsersList] = useState([])
    const [anTypes, setAnTypes] = useState([])
    const [restData, setRestData] = useState(null)
    const [anAccounts, setAnAccounts] = useState([])
    const [selectedANType, setSelectedANType] = useState(null)
    const [selectedANAccount, setSelectedANAccount] = useState(null)
    const loggedUserEmail = localStorage.getItem("email")

    const [parsedOffersData, setParsedOffersData] = useState([]);
    const [searchByColumnFields, setSearchByColumnFields] = useState([]);
    const [filterParams, setFilterParams] = useState({});
    let [tags, setTags] = useState([]);

    const menuStatus = useSelector(state => state.dataUploader.collapseMenu);
    const isVoluumCSVdifferentColumn = useSelector(state => state.dataUploader.isVoluumCSVdifferentColumn);
    const [csvData, setCsvData] = useState([]);
    const [voluumCsvData, setVoluumCsvData] = useState([]);
    const [popupStatus, setPopupStatus] = useState(false);
    const [urlSearchParams, setUrlSearchParams] = useState("");
    const [currentTabType, setCurrentTabType] = useState({value: 'offer', label: 'Deactivated Offer'});
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [formattedStartDate, setFormattedStartDate] = useState('')
    const [formattedEndDate, setFormattedEndDate] = useState('')
    const [showHint, setShowHint] = useState(false);

    const dispatch = useDispatch();

    const checkSession = (status) => {
        if(status === 401) {
            dispatch(logOut());
        }
    }
    
    const tabTitles = [
        {value: 'offer', label: 'Deactivated Offer'},
        {value: 'path', label: 'Deactivated Path'},
        {value: 'flow', label: 'Changed Flow'},
        {value: 'campaign', label: 'To Be Deactivated Campaign'},
        {value: 'offer', label: 'Reactivated Offer'},
    ]

    const token = localStorage.getItem("auth_token")

    const selectStyles = {
        container: (baseStyles) => ({
            ...baseStyles,
            maxWidth: "180px",
            width: "100%",
            margin: "0 20px 0 0",
        }),
        control: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: "14px",
            lineHeight: "1.2",
            minHeight: "26px",
            border: "none",
            borderRadius: "8px",
            "&:hover": {
                cursor: "pointer",
            },
        }),
        placeholder: (baseStyles) => ({
            ...baseStyles,
            fontWeight: 500,
            color: "#1A3353",
        }),
        menu: (baseStyles, state) => ({
            ...baseStyles,
            zIndex: "6",
            "&:hover": {
                cursor: "pointer",
            },
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: "14px",
            lineHeight: "1.2",
            "&:hover": {
                cursor: "pointer",
            },
        }),
    }

    const rowsLimitSelectStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: "14px",
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: "14px",
        })
    }

    /* fetch user services */
    useEffect(() => {
        setIsLoading(true);

        // initial fetch
        fetch(`${localStorage.getItem("apiUrl")}api/user/${selectedUser.id}/services/`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
        })
            .then(res => {
                checkSession(res.status);
                if (res.ok) {
                    return res.json()
                }
                return Promise.reject(res)
            })
            .then(res => {
                setSelectedUser({id: res.id, name: res.name})
                setUsersList([...res["user_managers_accounts"]])
                setAnTypes([])
                setAnAccounts([])
                if (res["user_services"] && res["user_services"].length > 0) {
                    let temp = []
                    let types = [] // for unique types
                    res["user_services"].forEach(item => {
                        if (item?.types?.name && !types.includes(item?.types?.name) && item?.types?.name === "voluum") {
                            types.push(item?.types?.name)
                            temp.push({value: item?.types?.name, label: item?.types?.name})
                        }
                    })
                    setAnTypes(temp)
                }

                setRestData(res)
                setIsLoading(false)
            })
            .catch(res => {
                setIsLoading(false)
                setConfirmationPopupText("Something went wrong")
                setShowConfirmationPopup(true)
            })
    }, []);

    useEffect(() => {
        if (totalRows > 0) {
            setTotalPagesCount(Math.ceil(totalRows / rowsLimit.value));
        } else {
            setTotalPagesCount(1);
        }
        setCurrentPage(1);
    }, [totalRows, rowsLimit]);

    useEffect(() => {
        const currentDate = new Date();
        const previousDay = new Date(currentDate.setDate(currentDate.getDate() - 1));

        setStartDate(previousDay);
        setEndDate(new Date()); // Resets to today's date
    }, []);

    useEffect(() => {
        if (startDate) {
            setFormattedStartDate(getCurrentDateFormatted(startDate));
        }
    }, [startDate]);

    useEffect(() => {
        if (endDate) {
            setFormattedEndDate(getCurrentDateFormatted(endDate));
        }
    }, [endDate]);

    useEffect(() => {
        if (selectedANAccount) {
            fetchCampaigns();
        }
    }, [rowsLimit, currentPage, selectedFilters, filterParams, tags, selectedANAccount, currentTabType, token]);

    useEffect(() => {
        if (restOffersData?.data) {
            let temp = [];
            if (restOffersData.data.length !== 0) {
                if (currentTabType.label === "Deactivated Offer" || currentTabType.label === "Reactivated Offer") {
                    temp.push({
                        label: "",
                        renderCell: (item) => (
                            <Link to={`/changed-campaigns-details/${selectedANAccount.value}/${item.voluum_offer_id}/${formattedStartDate}/${formattedEndDate}/${encodeURIComponent(item.voluum_offer_name)}`} target="_blank" rel="noopener noreferrer" className='show-details'>
                                <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 1L6 7.5L1 14" stroke={"#FFF"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </Link>
                        ),
                        key: "checkbox",
                        width: "8%",
                    });
                }
                for (let key in restOffersData.data[0]) {
                        let width = "20%";
                        temp.push({
                            label: key.replaceAll("_", " "),
                            renderCell: (item) => <span
                                className={"data-table-cell"}
                                title={"Click to copy"}
                                onClick={() => handleClickToCopy(item[key])}
                            >
                        {item[key]}
                    </span>,
                            resize: true,
                            key: key,
                            width: width,
                        });
                }
            }
            if (temp.length > 0) {
                Array.prototype.move = function (from, to) {
                    this.splice(to, 0, this.splice(from, 1)[0]);
                };
            }
            setColumnDefs(temp);
            const filteredColumnsByOrder = temp.filter(col => col.label !== "status" && col.label !== "" && col.label !== "voluum_name"  && col.label !== "click_cap"  && col.label !== "daily_cap");
            setOrderOptions(filteredColumnsByOrder.map(item => ({ value: item.key, label: item.key.replace("_", " ") })));
            setTotalRows(restOffersData["total"]);
            setTotalPagesCount(restOffersData["last_page"]);
        }
    }, [restOffersData]);

    /*  Psrsing offer_country and offer_domain fields  */
    useEffect(() => {
        parsingArrayFields();
    }, [restOffersData])

    const fetchCampaigns = async () => {
        setIsLoading(true);

        let urlOrderParam = "";
        if (selectedFilters.orderDirection && selectedFilters.orderBy) {
            urlOrderParam = `&offerSortColumn=${selectedFilters.orderBy.value}&offerSortDirection=${selectedFilters.orderDirection.value}`;
        }

        let urlColumnsToSearchParam = {};
        if (filterParams) {
            urlColumnsToSearchParam = { ...filterParams };
        }

        if (currentTabType.label === "Deactivated Offer" || currentTabType.label === "Reactivated Offer") {
            urlColumnsToSearchParam.status = {
                operator: "=",
                value: currentTabType.label === "Reactivated Offer" ? "activated" : "deactivated"
            };
        } else {
            delete urlColumnsToSearchParam.status;
        }

        let urlParams = `
        size=${rowsLimit.value}
        &page=${currentPage}
        ${urlOrderParam}
        &where=${encodeURI(JSON.stringify(urlColumnsToSearchParam))}
    `.replace(/\s{2,}|\n+/gm, ""); // RegExp to remove extra whitespace and line breaks

        try {
            const data = await getCampaigns(selectedANAccount?.value, currentTabType.value, urlParams, token, formattedStartDate, formattedEndDate);
            setRestOffersData(data.data);
            setCurrentFilterKeys(data.data.data[0] || {});
        } catch (error) {
            console.error(error.message)
            setConfirmationPopupText(error.message);
            setShowConfirmationPopup(true);
        } finally {
            setIsLoading(false);
        }
    };

    const parsingArrayFields = (offers = restOffersData.data) => {
        const currentOffers = offers;
        const correctOffers = [];
        if (currentOffers && offers.length) {
            for (let offer of currentOffers) {
                correctOffers.push({
                    ...offer,
                });
            }
            setParsedOffersData(correctOffers);
        }
    };

    const handleChangeSelectedUser = (user) => {
        setIsLoading(true);
        setSelectedANType(null)
        setSelectedANAccount(null)
        setParsedOffersData([])

        let filters = {
            ...selectedFilters,
            searchInColumns: {}
        };
        setSelectedFilters(filters);


        // fetch user services
        fetch(`${localStorage.getItem("apiUrl")}api/user/${user.value}/services`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`

            },
        })
            .then(res => {
                checkSession(res.status);
                if (res.ok) {
                    return res.json()
                }
                return Promise.reject(res)
            })
            .then(res => {
                setSelectedUser({
                    id: res.id,
                    name: res.name
                })
                setAnTypes([])
                setAnAccounts([])
                setRestOffersData([])
                if (res["user_services"] && res["user_services"].length > 0) {
                    let temp = []
                    let types = [] // for unique types
                    res["user_services"].forEach(item => {
                        if (item?.types?.name && !types.includes(item?.types?.name) && item?.types?.name === "voluum") {
                            types.push(item?.types?.name)
                            temp.push({value: item?.types?.name, label: item?.types?.name})
                        }
                    })
                    setAnTypes(temp)
                }
                setRestData(res)
                setIsLoading(false);
            })
            .catch(res => {
                setIsLoading(false)
                setConfirmationPopupText("Something went wrong")
                setShowConfirmationPopup(true)
            })
    }

    const handleChangeANType = (option) => {
        setSelectedANType(option)
        let temp = []
        if (restData["user_services"] && restData["user_services"].length > 0) {
            temp = restData["user_services"].filter(item => item?.types?.name === option.value)
            temp = temp.reduce((acc, item) => {
                acc.push({value: item.id, label: item.name})
                return acc
            }, [])
        }
        setAnAccounts(temp)
        setSelectedANAccount(null)
    }

    const handleChangeANAccount = async (option) => {
        setSelectedANAccount(option);
        setIsLoading(true);

        // TODO: this filter reset causes a redundant API call. Should figure out how to avoid it.
        let filters = {
            ...selectedFilters,
            searchInColumns: {}
        };
        setSelectedFilters(filters);

        const queryParams = new URLSearchParams({
            size: rowsLimit.value,
            dateFrom: formattedStartDate,
            dateTo: formattedEndDate
        }).toString();

        const accountID = option.value;
        try {
            const response = await fetch(`${localStorage.getItem("apiUrl")}api/voluum/changed-campaigns/${currentTabType.value}/${accountID}/?${queryParams}`, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`

                },
            });

            checkSession(response.status);

            if (!response.ok) {
                console.error("There are no offers for this account");
                setRestOffersData([]);
                setIsLoading(false);
                return;
            }

            const res = await response.json();

            if (!res || !res.data || !Array.isArray(res.data.data)) {
                console.error("Invalid offers data");
                setRestOffersData([]);
                setIsLoading(false);
                return;
            }

            setRestOffersData(res.data);
            setCurrentFilterKeys(res.data.data[0] || {});

            const modifiedData = [...(restOffersData?.data || []), ...(res.data.data || [])];
            setCsvData(prepareCSVData(modifiedData));
            setVoluumCsvData(modifiedData);

            filters = {
                ...selectedFilters,
                searchInColumns: {}
            };
            setSelectedFilters(filters);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
            setConfirmationPopupText("Something went wrong");
            setShowConfirmationPopup(true);
        }

        setIsLoading(false);
    };


    const setCurrentFilterKeys = (offer) => {
        const filterKeys = Object.keys(offer);
        let filteredKeys = filterKeys.filter(f => f !== "status" && f !== "click_cap" && f !== "daily_cap" && f !== "voluum_name");
        let filterValuesArr = [];

        for (let filterKey of filteredKeys) {

            const fieldLabel = filterKey.replaceAll("_", " ");
            const fieldType = (filterKey === "created_at"
                || filterKey === "updated_at"
                || filterKey === "last_deactivation_date"
                || filterKey === "last_activation_date") ? "date" : "string";

            filterValuesArr.push({
                value: filterKey,
                label: fieldLabel,
                type: fieldType,
            })
        }

        if (filterValuesArr.length > 0) {
            setSearchByColumnFields(filterValuesArr);
        }
    }

    const handleChangeSelectedFilters = (value) => {
        setSelectedFilters(value)
    }

    const handleClearSearchByColumnFilter = () => {
        setIsLoading(true);
        let temp = {
            ...selectedFilters,
            searchInColumns: {}
        };

        setFilterParams({});
        setTags([]);
        setSelectedFilters(temp);
        setIsLoading(false);
    }

    const handleDownloadExcel = async () => {
        setIsLoading(true);
        try {
            const data = await campaignsDownloadExcel(selectedANAccount.value, formattedStartDate, formattedEndDate, token);

            if (data.status === "success") {
                setConfirmationPopupText(data.message);
                setShowConfirmationPopup(true);
            }
        } catch (err) {
            console.error(err);
            setConfirmationPopupText('An error occurred while downloading the Excel file.');
            setShowConfirmationPopup(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleClickToCopy = (value) => {
        navigator.clipboard.writeText(value).then(() => {
        }, () => {
            console.error('Failed to copy');
        });
    }

    const handleDateChange = (update) => {
        const [start, end] = update;
        setStartDate(start);
        setShowHint(true)

        if (end) {
            const nextDay = new Date(end);
            nextDay.setDate(nextDay.getDate() + 1);
            setEndDate(nextDay);
            setShowHint(false)
        } else {
            setEndDate(null);
        }
    };

    const handleGetCampaignsWithDate = () => {
        if (selectedANAccount) {
            fetchCampaigns();
        }
    }

    if (isLoading) {
        return <div className='loading-wrap'>
            <Loader/>
        </div>
    }

    return (
        <>
            <div className={`changed-campaigns-container`}>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "0 0 10px",
                }}>

                    <Select
                        className={""}
                        styles={selectStyles}
                        options={usersList.reduce((acc, item) => {
                            acc.push({value: item.id, label: item.name})
                            return acc
                        }, [])}
                        isSearchable={true}
                        defaultValue={{value: selectedUser.id, label: selectedUser.name}}
                        name={"user"}
                        placeholder={"User"}
                        components={{
                            IndicatorSeparator: () => null
                        }}
                        onChange={(option) => handleChangeSelectedUser(option)}
                    />

                    <Select
                        className={""}
                        styles={selectStyles}
                        options={anTypes}
                        isSearchable={true}
                        defaultValue={selectedANType}
                        name={"affiliate_network_type"}
                        placeholder={"AN Type"}
                        components={{
                            IndicatorSeparator: () => null
                        }}
                        onChange={(option) => handleChangeANType(option)}
                    />

                    <Select
                        className={""}
                        styles={selectStyles}
                        options={anAccounts}
                        isSearchable={true}
                        defaultValue={selectedANAccount}
                        name={"affiliate_network_account"}
                        placeholder={"AN Account"}
                        value={selectedANAccount}
                        components={{
                            IndicatorSeparator: () => null
                        }}
                        onChange={(option) => handleChangeANAccount(option)}
                    />

                    <div className={"datepicker-container"}>
                        <DatePicker
                            onChange={handleDateChange}
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={moment().toDate()}
                            selectsRange={true}
                        />
                        <button className="blue-btn submit-selected-data" onClick={handleGetCampaignsWithDate}>Get data</button>
                        {showHint &&
                            <div className={"datepicker-range-hint"}>
                                <p>The date range cannot be longer than 14 days</p>
                            </div>
                        }
                    </div>

                    {restOffersData.length !== 0 &&
                        <button
                            className={`blue-btn ${!Boolean(restOffersData["data"].length !== 0) ? "disabled" : ""}`}
                            disabled={!Boolean(restOffersData["data"])}
                            onClick={handleDownloadExcel}
                        >
                            Download Excel
                        </button>}
                </div>


                <>
                    <AffiliateNetworkOffersTableFilter
                        orderByOptions={orderOptions}
                        orderDirectionOptions={orderDirectionOptions}
                        searchByColumnOptions={searchByColumnFields}
                        selectedFilters={selectedFilters}
                        changeSelectedFilters={handleChangeSelectedFilters}
                        clearSearchFilters={handleClearSearchByColumnFilter}
                        setFilterParams={setFilterParams}
                        filterParams={filterParams}
                        setTags={setTags}
                        tags={tags}
                    />

                    {restOffersData?.data && <ChangedCampaignsTabs
                        titles={tabTitles}
                        currentTab={currentTabType}
                        setCurrentTab={setCurrentTabType}
                    />}

                    <DataTable
                        data={parsedOffersData}
                        columnDefs={columnDefs}
                    />
                </>

                {restOffersData?.data && (
                    <div className="data-table-footer">
                        {rowsLimitOptions.length > 0 && (
                            //TODO: винести в окремий компонент
                            <Select
                                className={"data-table-rows-limit"}
                                styles={rowsLimitSelectStyles}
                                options={rowsLimitOptions}
                                isSearchable={false}
                                defaultValue={rowsLimit}
                                name={"rows_count"}
                                onChange={(option) => {
                                    setRowsLimit(option);
                                }}
                            />
                        )}
                        <div className={"data-table-rows-count"}>Total rows: <strong>{totalRows}</strong></div>
                        <Pagination
                            totalPagesCount={totalPagesCount}
                            setCurrentPage={(value) => setCurrentPage(value)}
                            currentPage={currentPage}
                        />
                    </div>
                )}
            </div>

            {showConfirmationPopup && (
                <MessageSendingData
                    messageText={confirmationPopupText}
                    setClosePopup={() => setShowConfirmationPopup(false)}
                />
            )}
        </>
    )
}

export default ChangedCampaignsList;
