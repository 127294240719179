import React, {useState, useEffect, useRef} from "react";
import {useDispatch} from "react-redux";
import {Field, Form, Formik} from "formik";
import CustomDatalist from "../../CustomDatalist";
import {setIsVoluumCSVdifferentColumn} from "../../../redux/dataUploader/dataSlice";
import {getAnOfferCountries} from "../../../helpers/get-an-offers-countries";
import Loader from "../../loaders/Loader";
import CsvDownloadNewFormat from "../../buttons/CsvDownloadNewFormat";
import MessageSendingData from "../MessageSendingData";

import CustomFileUploaderNoParse from "../../CustomFileUploaderNoParse";
import {UPLOADER_TYPE_TOP_OFFERS} from "../../../constants";
import BulkLookupSuccessPopup from "../../popups/BulkLookupSuccessPopup";
import BulkLookupErrorPopup from "../../popups/BulkLookupErrorPopup";
import CustomDoubledCheckbox from '../../CustomDoubledCheckbox';
import {logOut} from "../../../redux/dataUploader/dataSlice";

const CSVinVoluumFormatPopupMultipleSteps = ({csvData, closePopup, anAccount, anAccountLabel, selectedCompanyShortName, userId, selectedCountryFromFilter, selectedCountryOperator, urlSearchParams, restOffersData, columns, account}) => {
    const [initialValues, setInitialValues] = useState({ // eslint-disable-line no-unused-vars
        workspaceId: '',
        header: true,
        diffCol: false,
        includeAllAccounts: false,
        blogrefNoref: '',
    });

    const [currentValues, setCurrentValues] = useState({
        linksList: '',
        workspaceId: '',
        header: true,
        diffCol: false,
        includeAllAccounts: false,
        blogrefNoref: '',
    });

    const [formError, setFormError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('test1');
    const [uploadedFile, setUploadedFile] = useState();
    const [loading, setLoading] = useState(false);
    const [emailNotification, setEmailNotification] = useState(false);
    const [validationError, setValidationError] = useState(false);
    const [file, setFile] = useState([]);
    const [validationErrorMessage, setValidationErrorMessage] = useState('');
    const [includedAccounts, setIncludedAccounts] = useState([]);
    const [noSimilarAccounts, setNoSimilarAccounts] = useState('');
    const [includedAccountsLoader, setIncludedAccountsLoader] = useState(false);
    const dispatch = useDispatch();

    const checkSession = (status) => {
        if(status === 401) {
            dispatch(logOut());
        }
    }
    
    const sendDataBulkLookup = async (payload) => {
        const token = localStorage.getItem("auth_token");

        try {
            const response = await fetch(`${localStorage.getItem("apiUrl")}api/offers-bulk-lookup/${anAccount}/${userId}`, {

                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json'
                },
                body: payload
            });

            checkSession(response.status);
            
            if (!response.ok) {
                const errorText = await response.json();
                setValidationErrorMessage(errorText.error);
                setValidationError(true)
                return;
            }

            let result;

            try {
                result = await response.json();
            } catch (jsonError) {
                console.error('Failed to parse JSON:', jsonError.error);
                throw new Error('Invalid JSON response');
            }

            return result;

        } catch(e) {
            console.error('OffersBulkLookup response error : ', e);
            setErrorMessage(e);
            setFormError(true);
        }
    }

    const onSubmit = async () => {
        const diffColBoolean = Boolean(currentValues.diffCol);
        const headerBoolean = Boolean(currentValues.header);
        const includeAllAccountsBoolean = Boolean(currentValues.includeAllAccounts);

        const payload = new FormData();
        payload.append('offers_bulk_lookup_file', uploadedFile);
        payload.append('email', localStorage.getItem('email'));
        payload.append('domain_instead_of_offer', diffColBoolean);
        payload.append('include_column_names', headerBoolean);
        payload.append('include_all_accounts', includeAllAccountsBoolean);

        if(currentValues.workspaceId !== '') {
            payload.append('workspace_id', currentValues.workspaceId);   
        }

        if(currentValues.blogrefNoref !== '') {
            payload.append('blog_ref', currentValues.blogrefNoref);
        }

        setLoading(true);

        try {
            const results = await sendDataBulkLookup(payload);

            if(results.status === 'success') {
                setUploadedFile();
                setEmailNotification(true);
                setLoading(true);
            } 
            else {
                setLoading(false);
                setErrorMessage('Error fetching request');
            }

        } catch (e) {
            setLoading(false);
            setErrorMessage(e);
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        setFormError(false);
        setErrorMessage('');

        if(uploadedFile) {
            return;
        }
    }, [uploadedFile, file]);
    
    const validateWorkspaceId = (value, setCurrentValues) => {
        setCurrentValues(previousState => {
            return {...previousState, workspaceId: value}
        });
    }

    const validateHeader = (value, setCurrentValues) => {
        setCurrentValues(previousState => {
            return {...previousState, header: value}
        });
    }

    const validateAccounts = async (value, setCurrentValues) => {
        
        setCurrentValues(previousState => {
            return {...previousState, includeAllAccounts: value}
        });

        if(value && !currentValues.includeAllAccounts && includedAccounts.length === 0) {
            setIncludedAccountsLoader(true);
            const token = localStorage.getItem("auth_token");
            
            try {
                const response = await fetch(`${localStorage.getItem("apiUrl")}api/similar-services/${anAccount}/${userId}`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json'
                    }
                });

                checkSession(response.status);
                
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                if(Array.isArray(result.data) && result.data.length === 0) {
                    setIncludedAccountsLoader(false);
                } else if (Array.isArray(result.data)) {
                    const names = result.data.map(item => item.service_name).filter(name => name);
                    
                    setIncludedAccounts(names);
                    setIncludedAccountsLoader(false);
                    setNoSimilarAccounts('');
                } else {
                    setIncludedAccountsLoader(false);
                }
            } catch (error) {
                console.error("Fetch error:", error);
                setIncludedAccountsLoader(false);
            }
        } else {

            setNoSimilarAccounts('');
        }
    }

    const validateDiffColumn = (value, setCurrentValues) => {
        setCurrentValues(previousState => {
            return {...previousState, diffCol: value}
        });
    }
    const validateBlogrefNoref = (value, setCurrentValues) => {
        setCurrentValues(previousState => {
            return {...previousState, blogrefNoref: value}
        });
        let error;
        const expression = /^https:\/\/(?:(?!www\.))[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#&//=]*)\?\b([-a-zA-Z0-9()@:%_{}\+.~#&//=]*)\=$/;
        const regex = new RegExp(expression);
        if (value && !value.match(regex)) {
            error = 'Value have to started with "https://", no "www.", include "?", end with "="';
        } 
        return error;
    }

    return (
        <div className='add-item-popup-container voluum-popup-container'>
            <div className="add-item-popup-block">
                <div className='form-wrap'>
                    <div className="title-wrap">
                        <h1>Bulk Lookup</h1>
                        <div className="close-btn" onClick={closePopup}>
                            <img src={`${process.env.PUBLIC_URL}/images/close-btn.svg`} alt="close popup"/>
                        </div>
                    </div>
                    <div className="form-flex-container">
                        <Formik
                            initialValues={initialValues}
                            onSubmit={onSubmit}>
                            {({errors, touched}) => (
                                <Form className="file-upload">
                                    <p className='yk-checker-text'>Affiliate Networks Offers Bulk Lookup. Upload a file (max 1 MB) here:</p>
                                    <Field>
                                        {() => (
                                            <CustomFileUploaderNoParse
                                                type={UPLOADER_TYPE_TOP_OFFERS}
                                                setFileLinksList={setUploadedFile}
                                                currentValues={currentValues}
                                                setFormError={setFormError}
                                                setFile={setFile}
                                                file={file}
                                            />
                                        )}
                                    </Field>
                                    <div className={'fileDescription'}>
                                        <p>The file format should be in the next format:</p>
                                        <span>
                                            You can use the following column names in the uploaded file: <br/>offer_domain, offer_name, offer_tracking_link, offer_id, offer_country.<br/>
                                            It's possible to use only one column or some combinations, like: <br/>offer_domain + offer_country (two separate columns).
                                        </span>
                                    </div>
                                    <div className='input-block config-item-block checkbox-block'>
                                        <Field
                                            type="checkbox"
                                            className="toggle-button"
                                            id='diffCol'
                                            name="diffCol"
                                            validate={value => validateDiffColumn(value, setCurrentValues)}
                                        />
                                        <label htmlFor='diff-column' className="toggle-label">Include "Domain"
                                            column instead of "Offer Name"</label>
                                    </div>
                                    <div className="input-block without-config-item-block">
                                        <label htmlFor="workspaceId">Workspace Id (if needed)</label>
                                        <Field
                                            id="workspaceId"
                                            name="workspaceId"
                                            type='text'
                                            validate={value => validateWorkspaceId(value, setCurrentValues)}
                                        />
                                        {errors.workspaceId && touched.workspaceId ?
                                            <div className='form-error'>{errors.workspaceId}</div> : null}
                                    </div>

                                    <div className='input-block config-item-block checkbox-block'>
                                        <Field
                                            type="checkbox"
                                            className="toggle-button"
                                            id='header'
                                            name="header"
                                            validate={value => validateHeader(value, setCurrentValues)}
                                        />
                                        <label htmlFor='header' className="toggle-label">Include column
                                            names</label>
                                    </div>

                                    <div className='input-block config-item-block checkbox-block'>
                                        <Field
                                            type="checkbox"
                                            className="toggle-button"
                                            id='includeAllAccounts'
                                            name="includeAllAccounts"
                                            validate={value => validateAccounts(value, setCurrentValues)}
                                        />
                                        <label htmlFor='includeAllAccounts' className="toggle-label">Include similar accounts</label>
                                    </div>

                                    <div className="input-block without-config-item-block after-checkbox-block">
                                        {includedAccountsLoader && includedAccounts.length === 0 && !noSimilarAccounts ? <Loader /> : null}
                                        {includedAccounts && currentValues.includeAllAccounts && !includedAccountsLoader ?
                                            <div>
                                                <div className="scrolled-block">
                                                    <p>Main: </p>
                                                    <ul>
                                                        <li>{anAccountLabel}</li>
                                                    </ul>
                                                    <p>Similar {selectedCompanyShortName ? <span>{selectedCompanyShortName} company</span> : null} accounts: </p>
                                                    <ul>
                                                        {includedAccounts.length > 0 ? includedAccounts.map((item, index) => (
                                                            <li key={index}>{item}</li>
                                                        )) : <li>No similar accounts are found</li>}
                                                    </ul>
                                                </div>
                                            </div> : null}
                                    </div>
                                    <div className="input-block without-config-item-block after-checkbox-block">
                                            <label htmlFor="blogrefNoref">Blogref/noref</label>
                                            <Field
                                                id="blogrefNoref"
                                                name="blogrefNoref"
                                                type='text'
                                                validate={value => validateBlogrefNoref(value, setCurrentValues)}
                                            />
                                            {errors.blogrefNoref && touched.blogrefNoref ?
                                                <div className='form-error'>{errors.blogrefNoref}</div> : null}
                                        </div>
                                    
                                    {formError && <span className='yk-checker-form-error'>{errorMessage === '' ? 'Something went wrong.' : errorMessage}</span>}

                                    <div className="input-block without-config-item-block after-checkbox-block">{loading ? <Loader /> : 
                                        <button className='blue-btn form-submit'
                                            type="submit"
                                            disabled={formError || !uploadedFile || loading}
                                        >Submit</button>
                                    }</div>

                                    <BulkLookupSuccessPopup
                                        open={emailNotification}
                                        handleClose={setEmailNotification}
                                        closeBulkLookupPopup={closePopup}
                                    />
                                    <BulkLookupErrorPopup
                                        open={validationError} 
                                        handleClose={setValidationError}  
                                        errorMessage={validationErrorMessage} 
                                    />
                                </Form>
                            )}
                        </Formik>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default CSVinVoluumFormatPopupMultipleSteps;
