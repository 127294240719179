import React, {useEffect, useState} from "react";
import Select from "react-select";
import Loader from "../../components/loaders/Loader";
import PreTableProgramManagement from "../../components/tables/PreTableProgramManagement";
import Pagination from "../../components/Pagination";
import ProgramManagementOffersTableFilter from "../../components/ProgramManagementOffersTableFilter";
import {
    orderDirectionOptions,
    rowsLimitOptions,
} from "./table-options";
import "./program-management.scss";
import MessageSendingData from "../../components/popups/MessageSendingData";
import {AUTH_TOKEN} from "../../constants/Auth";
import {logOut} from "../../redux/dataUploader/dataSlice";
import {useDispatch} from "react-redux";

const ProgramManagement = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [restOffersData, setRestOffersData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const [rowsLimit, setRowsLimit] = useState({value: "10", label: "10 / page"});
    const [totalRows, setTotalRows] = useState(0);
    const [totalPagesCount, setTotalPagesCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedFilters, setSelectedFilters] = useState({
        orderDirection: orderDirectionOptions[0],
        searchInColumns: {},
    });
    const [orderOptions, setOrderOptions] = useState([]);
    const [selectedUser, setSelectedUser] = useState({
        id: localStorage.getItem("id"),
        name: localStorage.getItem("name"),
    })
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false)
    const [confirmationPopupText, setConfirmationPopupText] = useState("")
    const [usersList, setUsersList] = useState([])
    const [anTypes, setAnTypes] = useState([])
    const [restData, setRestData] = useState(null)
    const [anAccounts, setAnAccounts] = useState([])
    const [selectedANType, setSelectedANType] = useState(null)
    const [selectedANAccount, setSelectedANAccount] = useState(null)

    const [parsedOffersData, setParsedOffersData] = useState([]);
    const [searchByColumnFields, setSearchByColumnFields] = useState([]);
    const [filterParams, setFilterParams] = useState({});
    let [tags, setTags] = useState([]);

    const [selectedOffersId, setSelectedOffersId] = useState([]);

    const excludeKeys = ['category', 'id', 'account_id', "isAcceptable"];

    const localToken = localStorage.getItem(AUTH_TOKEN);
    const dispatch = useDispatch();

    const checkSession = (status) => {
        if(status === 401) {
            dispatch(logOut());
        }
    }
    
    const selectStyles = {
        container: (baseStyles) => ({
            ...baseStyles,
            maxWidth: "180px",
            width: "100%",
            margin: "0 20px 0 0",
        }),
        control: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: "14px",
            lineHeight: "1.2",
            minHeight: "26px",
            border: "none",
            borderRadius: "8px",
            "&:hover": {
                cursor: "pointer",
            },
        }),
        placeholder: (baseStyles) => ({
            ...baseStyles,
            fontWeight: 500,
            color: "#1A3353",
        }),
        menu: (baseStyles, state) => ({
            ...baseStyles,
            zIndex: "6",
            "&:hover": {
                cursor: "pointer",
            },
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: "14px",
            lineHeight: "1.2",
            "&:hover": {
                cursor: "pointer",
            },
        }),
    }
    
    const rowsLimitSelectStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: "14px",
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: "14px",
        })
    }

    useEffect(() => {

        if (totalRows > 0) {
            setTotalPagesCount(Math.ceil(totalRows / rowsLimit.value));
        } else {
            setTotalPagesCount(1);
        }
        setCurrentPage(1);
    }, [totalRows, rowsLimit]);

    /* fetch user services */
    useEffect(() => {
        setIsLoading(true);
        // initial fetch
        fetch(`${localStorage.getItem('apiUrl')}api/user/${selectedUser.id}/services/`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localToken}`
            },
        })
            .then(res => {
                checkSession(res.status);
                if (res.ok) {
                    return res.json()
                }
                return Promise.reject(res)
            })
            .then(res => {
                setSelectedUser({id: res.id, name: res.name})
                setUsersList([...res["user_managers_accounts"]])
                setAnTypes([])
                setAnAccounts([])
                if (res["user_services"] && res["user_services"].length > 0) {

                    let temp = []
                    let types = [] // for unique types
                    res["user_services"].forEach(item => {
                        if (item?.types?.name && !types.includes(item?.types?.name) && (item?.types?.name === "webgains" || item?.types?.name === "tradedoubler")) {
                            types.push(item?.types?.name);
                            temp.push({value: item?.types?.name, label: item?.types?.name});
                        }
                    });
                    setAnTypes(temp)
                }

                setRestData(res)
                setIsLoading(false)
            })
            .catch(res => {
                setIsLoading(false)
                setConfirmationPopupText("Something went wrong")
                setShowConfirmationPopup(true)
            })
    }, []);

    /* fetch offer_directions */
    useEffect(() => {
        fetchData();
    }, [rowsLimit, currentPage, selectedFilters ,selectedFilters, tags]);

    useEffect(() => {
        if (restOffersData?.data?.length > 0) {
            const keys = Object.keys(restOffersData.data[0]).filter(key => !excludeKeys.includes(key));

            let temp = [];

            if (restOffersData.data.length !== 0) {

                temp.push({
                    label: "",
                    renderCell: (item) => (
                        item?.isAcceptable === true ? (
                            <input
                                type="checkbox"
                                onChange={() => handleOfferCheckbox(item)}
                                checked={selectedOffersId.includes(item.id)}
                            />
                        ) : null
                    ),
                    key: "checkbox",
                    width: "6%",
                });

                temp.push({
                    label: "",
                    renderCell: (item) => (
                        item?.isAcceptable === true ? (
                            <button onClick={() => handleApplyToSingleOffer(item.id)} className={"blue-btn"}>Join</button>
                        ) : null
                    ),
                    key: "button",
                    width: "9%",
                });

                for (let key of keys) {
                    if (!excludeKeys.includes(key)) {
                        let label = key.replaceAll("_", " ");
                        if (label === "name") label = "offer name";
                        if (label === "status") label = "membership status";

                        let width = "20%";
                        temp.push({
                            label: label,
                            renderCell: (item) => {
                                if (key === "status") {
                                    return (
                                        <span
                                            key={item.id}
                                            className={`status-tag ${item[key] === "Not Connected" ? "not-connected"
                                                : item[key] === "Under consideration" ? "under-consideration" 
                                                    : item[key] === "Hold UC" ? "hold-uc"
                                                        : item[key]}`}
                                        >
                                        {item[key]}
                                    </span>
                                    );
                                } else {
                                    return (
                                        <span
                                            key={item.id}
                                            className={"data-table-cell"}
                                            title={"Click to copy"}
                                            onClick={() => handleClickToCopy(item[key])}
                                        >
                                        {item[key]}
                                    </span>
                                    );
                                }
                            },
                            resize: true,
                            key: key,
                            width: width,
                        });
                    }
                }
            }

            setColumnDefs(temp);
            setOrderOptions(
                temp
                    .filter(item => item.key !== "checkbox" && item.key !== "button")
                    .map(item => {
                        let label = item.key.replace("_", " ");
                        if (item.key === "name") {
                            label = "offer name";
                        } else if (item.key === "status") {
                            label = "membership status";
                        }
                        return { value: item.key, label };
                    })
            );
            setTotalRows(restOffersData["total"]);
            setTotalPagesCount(restOffersData["last_page"]);
        } else {
            setColumnDefs([]);
            setOrderOptions([]);
            setTotalRows(0);
            setTotalPagesCount(1);
        }
    }, [restOffersData, selectedOffersId]);

    /*  Psrsing offer_country and offer_domain fields  */
    useEffect(() => {
        parsingArrayFields();
    }, [restOffersData.data])

    const fetchData = () => {
        if (restOffersData.data) {
            setIsLoading(true);

            let urlOrderParam = ""
            if (selectedFilters.orderDirection && selectedFilters.orderBy) {
                urlOrderParam = `&programsSortColumn=${selectedFilters.orderBy.value}&programsSortDirection=${selectedFilters.orderDirection.value}`
            }

            let urlColumnsToSearchParam = {};
            if (filterParams) {
                urlColumnsToSearchParam = {...filterParams};
            }

            let urlParams = `
                ?size=${rowsLimit.value}
                &page=${currentPage}
                ${urlOrderParam}
                &where=${encodeURI(JSON.stringify(urlColumnsToSearchParam))}
            `.replace(/\s{2,}|\n+/gm, ""); //regexp removes more than two whitespaces one by one and linebreaks

            // fetch offers on filters change
            if (selectedANAccount) {                
                fetch(`${localStorage.getItem('apiUrl')}api/programs/${selectedANAccount.value}${urlParams}`, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localToken}`,
                    },
                })
                    .then(res => {
                        checkSession(res.status);
                        if (res.status !== 200) {
                            return Promise.reject(res)
                        }
                        return res.json()
                    })
                    .then(res => {
                        setRestOffersData(res.data)
                        let temp = []
                        
                        setOrderOptions(temp);
                        setIsLoading(false);
                        setSelectedOffersId([]);

                    })
                    .catch(err => {
                        console.error(err)
                        setIsLoading(false)
                        setConfirmationPopupText("Something went wrong")
                        setShowConfirmationPopup(true)
                    })
            }
        }
    }

    const handleOfferCheckbox = (offer) => {
            setSelectedOffersId(prevSelectedOffersId => {
                if (prevSelectedOffersId.includes(offer.id)) {
                    return prevSelectedOffersId.filter(offerId => offerId !== offer.id);
                } else {
                    return [...prevSelectedOffersId, offer.id];
                }
            });
    };

    const handleSelectAllOffersOnPage = () => {
        restOffersData.data
            .filter(offer => offer.isAcceptable === true)
            .forEach((offer) => {
                setSelectedOffersId(prevSelectedOffersId => {
                    if (prevSelectedOffersId.includes(offer.id)) {
                        return prevSelectedOffersId.filter(offerId => offerId !== offer.id);
                    } else {
                        return [...prevSelectedOffersId, offer.id];
                    }
                });
            });
    };

    const handleApplyToSelectedOffers = async () => {
        setIsLoading(true);

        const numericIds = selectedOffersId.map(id => Number(id));

        fetch(`${localStorage.getItem('apiUrl')}api/programs/request/${selectedANAccount.value}`, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localToken}`,
            },
            body: JSON.stringify({ ids: numericIds }),
        })
            .then(res => checkSession(res.status))
            .then(res => res.json())
            .then((res) => {
                setRestOffersData(res?.data?.data);
                let messages = [];

                // Handle errors
                if (res?.result?.error) {
                    if (selectedANType.value === "webgains") {
                        Object.entries(res.result.error).forEach(([id, errorObj]) => {
                            if (errorObj.messages && typeof errorObj.messages === 'object') {
                                Object.entries(errorObj.messages).forEach(([msgKey, msgArray]) => {
                                    msgArray.forEach((msg) => {
                                        messages.push(`Error in offer ${id}: ${msg}`);
                                    });
                                });
                            } else {
                                messages.push(`Error in offer ${id}: ${errorObj.title || "Unknown error occurred"}`);
                            }
                        });
                    } else if (selectedANType.value === "tradedoubler") {
                        Object.entries(res.result.error).forEach(([id, errorArray]) => {
                            errorArray.forEach((errorObj) => {
                                messages.push(`Error in offer ${id}: ${errorObj.message || "Unknown error occurred"}`);
                            });
                        });
                    }
                }

                if (res?.result?.success) {
                    Object.entries(res.result.success).forEach(([id, successObj]) => {
                        messages.push(`Success in offer ${id}: ${successObj.message || "Successfully applied"}`);
                    });
                }

                if (messages.length === 0) {
                    messages.push("All offers successfully applied");
                }

                fetchData();
                setConfirmationPopupText(messages);
                setIsLoading(false);
                setSelectedOffersId([]);
                setShowConfirmationPopup(true);
            })
            .catch(err => {
                console.error(err);
                const title = err?.result?.[0]?.title || "Something went wrong";
                setConfirmationPopupText(title);
                setIsLoading(false);
                setShowConfirmationPopup(true);
            });
    };


    const handleApplyToSingleOffer = (id) => {
        setIsLoading(true);

        const numberId = Number(id);

        fetch(`${localStorage.getItem('apiUrl')}api/programs/request/${selectedANAccount.value}`, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localToken}`,
            },
            body: JSON.stringify({ ids: [numberId] }),
        })
            .then(res => checkSession(res.status))
            .then(res => res.json())
            .then((res) => {
                setRestOffersData(res?.data?.data);
                let messages = [];

                // Handle errors
                if (res?.result?.error) {
                    if (selectedANType.value === "webgains") {
                        Object.entries(res.result.error).forEach(([id, errorObj]) => {
                            if (errorObj.messages && typeof errorObj.messages === 'object') {
                                Object.entries(errorObj.messages).forEach(([msgKey, msgArray]) => {
                                    msgArray.forEach((msg) => {
                                        messages.push(`Error in offer ${id}: ${msg}`);
                                    });
                                });
                            } else {
                                messages.push(`Error in offer ${id}: ${errorObj.title || "Unknown error occurred"}`);
                            }
                        });
                    } else if (selectedANType.value === "tradedoubler") {
                        Object.entries(res.result.error).forEach(([id, errorArray]) => {
                            errorArray.forEach((errorObj) => {
                                messages.push(`Error in offer ${id}: ${errorObj.message || "Unknown error occurred"}`);
                            });
                        });
                    }
                }

                // Handle successes
                if (res?.result?.success) {
                    Object.entries(res.result.success).forEach(([id, successObj]) => {
                        messages.push(`Success in offer ${id}: ${successObj.message || "Successfully applied"}`);
                    });
                }

                if (messages.length === 0) {
                    messages.push("Offer successfully applied");
                }

                fetchData();
                setConfirmationPopupText(messages.join("\n"));
                setIsLoading(false);
                setSelectedOffersId([]);
                setShowConfirmationPopup(true);
            })
            .catch(err => {
                console.error(err);
                const title = err?.result?.[0]?.title || "Something went wrong";
                setConfirmationPopupText(title);
                setIsLoading(false);
                setShowConfirmationPopup(true);
            });
    };

    const parsingArrayFields = (offers = restOffersData.data) => {
        const currentOffers = offers;
        const correctOffers = [];
        if (currentOffers && offers.length) {
            for (let offer of currentOffers) {
                correctOffers.push({
                    ...offer,
                });
            }
            setParsedOffersData(correctOffers);
        }
    };


    const handleChangeSelectedUser = (user) => {
        setIsLoading(true);
        setSelectedANType(null)
        setSelectedANAccount(null)
        setParsedOffersData([])

        let filters = {
            ...selectedFilters,
            searchInColumns: {}
        };
        setSelectedFilters(filters);

        // fetch user services
        fetch(`${localStorage.getItem('apiUrl')}api/user/${user.value}/services`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localToken}`
            },
        })
            .then(res => {
                checkSession(res.status);
                if (res.ok) {
                    return res.json()
                }
                return Promise.reject(res)
            })
            .then(res => {
                setSelectedUser({
                    id: res.id,
                    name: res.name
                })
                setAnTypes([])
                setAnAccounts([])
                setRestOffersData([])
                if (res["user_services"] && res["user_services"].length > 0) {
                    let temp = []
                    let types = [] // for unique types
                    res["user_services"].forEach(item => {
                        if (item?.types?.name && !types.includes(item?.types?.name) && (item?.types?.name === "webgains" || item?.types?.name === "tradedoubler")) {
                            types.push(item?.types?.name);
                            temp.push({value: item?.types?.name, label: item?.types?.name});
                        }
                    });
                    setAnTypes(temp)
                }
                setRestData(res)
                setIsLoading(false);
            })
            .catch(res => {
                setIsLoading(false)
                setConfirmationPopupText("Something went wrong")
                setShowConfirmationPopup(true)
            })
    }

    const handleChangeANType = (option) => {
        setSelectedANType(option)
        let temp = []
        if (restData["user_services"] && restData["user_services"].length > 0) {
            temp = restData["user_services"].filter(item => item?.types?.name === option.value)
            temp = temp.reduce((acc, item) => {
                acc.push({value: item.id, label: item.name})
                return acc
            }, [])
        }
 
        setAnAccounts(temp);
        setSelectedANAccount(null);
    }

    const handleChangeANAccount = async (option) => {
        handleRemoveColumnFromSearch('status');
        setSelectedANAccount(option);
        setIsLoading(true);
        // TODO: this filter reset causes a redundant API call. Should figure out how to avoid it.
        let filters = {
            ...selectedFilters,
            searchInColumns: {}
        };
        setSelectedFilters(filters);

        const accountID = option.value;
        try {
            const response = await fetch(`${localStorage.getItem('apiUrl')}api/programs/${accountID}?size=${rowsLimit.value}`, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localToken}`,
                },
            });

            checkSession(res.status);

            if (!response.ok) {
                console.error("There are no offers for this account");
                setRestOffersData([]);
                setIsLoading(false);
                return;
            }

            const res = await response.json();

            if (!res || !res.data || !Array.isArray(res.data.data)) {
                console.error("Invalid offers data");
                setRestOffersData([]);
                setIsLoading(false);
                return;
            }

            setRestOffersData(res.data);
            setCurrentFilterKeys(res.data.data[0] || {});

            filters = {
                ...selectedFilters,
                searchInColumns: {}
            };
            setSelectedFilters(filters);

        } catch (error) {
            console.error(error);
            setIsLoading(false);
            setConfirmationPopupText("Something went wrong");
            setShowConfirmationPopup(true);
        }
        // getOffersWithSearchParamsDefault();

        setIsLoading(false);
    };

    const setCurrentFilterKeys = (offer) => {
        const filterKeys = Object.keys(offer);
        let filteredKeys = filterKeys.filter(f => !excludeKeys.includes(f));

        let filterValuesArr = filteredKeys.map(filterKey => {
            let fieldLabel = filterKey.replaceAll("_", " ");
            if (filterKey === "name") {
                fieldLabel = "offer name";
            } else if (filterKey === "status") {
                fieldLabel = "membership status";
            }
            const fieldType = ["created_at", "updated_at", "requested_at"].includes(filterKey) ? "date" : "string";

            return {
                value: filterKey,
                label: fieldLabel,
                type: fieldType,
            };
        });

        if (filterValuesArr.length > 0) {
            setSearchByColumnFields(filterValuesArr);
        }
    };

    const handleGettingOfferWithSearchParams = async (filters = {}) => {
        setIsLoading(true);

        let urlOrderParam = ""
        if (selectedFilters.orderDirection && selectedFilters.orderBy) {
            urlOrderParam = `programsSortColumn=${selectedFilters.orderBy.value}&programsSortDirection=${selectedFilters.orderDirection.value}`
        }

        let urlColumnsToSearchParam = {};
        if (filterParams) {
            urlColumnsToSearchParam = {...filters};
        }

        const urlParams = `
            ${urlOrderParam}
            &where=${encodeURI(JSON.stringify(urlColumnsToSearchParam))}
        `.replace(/\s{2,}|\n+/gm, ""); //regexp removes more than two whitespaces one by one and linebreaks

        if(selectedANAccount) {
             
            fetch(`${localStorage.getItem('apiUrl')}api/programs/${selectedANAccount.value}?${urlParams}`, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localToken}`,
                }
            })
            .then(res => {
                checkSession(res.status);
                if (res.ok) {
                    return res.json()
                }
                return Promise.reject(res)
            })
            .then(res => {
                setTotalRows(res.data.total);
                setParsedOffersData(res.data.data);
                parsingArrayFields(res.data.data);
            })
            .catch(e => console.error(e))

        }

        setIsLoading(false);
    }

    const handleChangeSelectedFilters = (value) => {
        setSelectedFilters(value)
    }

    const handleClearSearchByColumnFilter = () => {
        setIsLoading(true);
        let temp = {
            ...selectedFilters,
            searchInColumns: {}
        };

        setFilterParams({});
        setTags([]);
        setSelectedFilters(temp);
        setIsLoading(false);
    }

    const handleClickToCopy = (value) => {
        navigator.clipboard.writeText(value).then(() => {
        }, () => {
            console.error('Failed to copy');
        });
    }

    const getOffersWithSearchParamsDefault = () => {

        let removedStatusTags;
        if(Array.isArray(tags)) {
            removedStatusTags = tags.filter(obj => obj.name !== 'status');
        }

        let removeStatusFilterParams;
        if(typeof filterParams === 'object' && filterParams !== null) {
            removeStatusFilterParams = Object.keys(filterParams).reduce((acc, key) => {
                if (key !== 'status') {
                  acc[key] = filterParams[key];
                }
                return acc;
              }, {});
        }

        let filterParam = {};
        let temp = {};
        let tempTag = [...removedStatusTags];
        
        filterParam['status'] = {
            operator: '=',
            value: 'Not Connected'
        }

        setTags(tempTag);
        temp = Object.assign(removeStatusFilterParams, filterParam)
        setFilterParams({...temp});
        handleGettingOfferWithSearchParams(temp);
    }

    const handleRemoveColumnFromSearch = (name) => {
        let temp = [...tags];
        let tempFilters = {...filterParams};
        let filteredTags = temp.filter(t => t.name !== name);

        delete tempFilters[name];
        setTags(filteredTags);
        setFilterParams(tempFilters);
        handleGettingOfferWithSearchParams(tempFilters);
    }

    if (isLoading) {
        return <div className='loading-wrap'><Loader /></div>
    }

    return (
        <>
            <div className={`program-management-container`}>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "0 0 10px",
                }}>


                    <Select
                        className={""}
                        styles={selectStyles}
                        options={usersList.reduce((acc, item) => {
                            acc.push({value: item.id, label: item.name})
                            return acc
                        }, [])}
                        isSearchable={true}
                        defaultValue={{value: selectedUser.id, label: selectedUser.name}}
                        name={"user"}
                        placeholder={"User"}
                        components={{
                            IndicatorSeparator: () => null
                        }}
                        onChange={(option) => handleChangeSelectedUser(option)}
                    />

                    <Select
                        className={""}
                        styles={selectStyles}
                        options={anTypes}
                        isSearchable={true}
                        defaultValue={selectedANType}
                        name={"affiliate_network_type"}
                        placeholder={"AN Type"}
                        components={{
                            IndicatorSeparator: () => null
                        }}
                        onChange={(option) => handleChangeANType(option)}
                    />

                    <Select
                        className={""}
                        styles={selectStyles}
                        options={anAccounts}
                        isSearchable={true}
                        defaultValue={selectedANAccount}
                        name={"affiliate_network_account"}
                        placeholder={"AN Account"}
                        value={selectedANAccount}
                        components={{
                            IndicatorSeparator: () => null
                        }}
                        onChange={(option) => handleChangeANAccount(option)}
                    />

                    {selectedOffersId.length !== 0 && <div className={"hint-selected-offers"}>
                        <h2>Offers selected: {selectedOffersId.length}</h2>
                    </div>}
                </div>

                <>
                    <ProgramManagementOffersTableFilter
                        orderByOptions={orderOptions}
                        orderDirectionOptions={orderDirectionOptions}
                        searchByColumnOptions={searchByColumnFields}
                        selectedFilters={selectedFilters}
                        handleGettingOfferWithSearchParams={handleGettingOfferWithSearchParams}
                        changeSelectedFilters={handleChangeSelectedFilters}
                        clearSearchFilters={handleClearSearchByColumnFilter}
                        setFilterParams={setFilterParams}
                        filterParams={filterParams}
                        setTags={setTags}
                        selectedANAccount={selectedANAccount}
                        tags={tags}
                    />

                    {selectedANAccount && <div className="buttons-container">
                        <button
                        className={"green-btn"}
                        onClick={getOffersWithSearchParamsDefault}
                        disabled={!Boolean(selectedANAccount) || ''}
                        >
                            Offers for Joining
                        </button>
                        <button
                        className={"white-btn"}
                        onClick={() => handleRemoveColumnFromSearch('status')}
                        disabled={!Boolean(selectedANAccount) || ''}
                        >
                            Show All Offers 
                        </button>
                    </div>}

                    {restOffersData.data && restOffersData.data.length !== 0 && <div className="buttons-container">
                        <button onClick={handleSelectAllOffersOnPage} className="blue-btn">Select all</button>
                        <button onClick={() => setSelectedOffersId([])}
                                className={`blue-btn ${selectedOffersId.length === 0 ? 'disabled' : ''}`}>Unselect all
                        </button>
                        <button
                            onClick={handleApplyToSelectedOffers}
                            className={`green-btn ${selectedOffersId.length === 0 ? 'disabled' : ''}`}
                            disabled={selectedOffersId.length === 0}>
                            Join selected
                        </button>
                    </div>}

                    <PreTableProgramManagement
                        data={parsedOffersData}
                        columnDefs={columnDefs}
                    />
                </>

                {restOffersData.data && (
                    <div className="data-table-footer">
                        {rowsLimitOptions.length > 0 && (
                            //TODO: винести в окремий компонент
                            <Select
                                className={"data-table-rows-limit"}
                                styles={rowsLimitSelectStyles}
                                options={rowsLimitOptions}
                                isSearchable={false}
                                defaultValue={rowsLimit}
                                name={"rows_count"}
                                onChange={(option) => {
                                    setRowsLimit(option);
                                }}
                            />
                        )}
                        <div className={"data-table-rows-count"}>Total rows: <strong>{totalRows}</strong></div>
                        <Pagination
                            totalPagesCount={totalPagesCount}
                            setCurrentPage={(value) => setCurrentPage(value)}
                            currentPage={currentPage}
                        />
                    </div>
                )}
            </div>

            {showConfirmationPopup && (
                <MessageSendingData
                    messageText={confirmationPopupText}
                    setClosePopup={() => setShowConfirmationPopup(false)}
                />
            )}
        </>
    )
}

export default ProgramManagement;