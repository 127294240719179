import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import '../buttons.scss';
import Loader from "../../loaders/Loader";
import {AUTH_TOKEN} from "../../../constants/Auth";
import {logOut} from "../../../redux/dataUploader/dataSlice";
import {useDispatch} from "react-redux";

const CsvDownloadNewFormat = ({
                                  filename,
                                  children,
                                  separator = ',',
                                  enclosingCharacter = '"',
                                  headers = null,
                                  totalOffers,
                                  urlSearchParams,
                                  anAccount,
                                  userId,
                                  params,
                                  formError,
                                  setShowConfirmationPopup,
                                  setConfirmationPopupText
                              }) => {
    const [csvData, setCsvData] = useState([]);
    const [headersData, setHeadersData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dataReady, setDataReady] = useState(false);
    const localToken = localStorage.getItem(AUTH_TOKEN);
    const dispatch = useDispatch();

    const checkSession = (status) => {
        if(status === 401) {
            dispatch(logOut());
        }
    }

    const handleDownload = async () => {
        setLoading(true);
        setDataReady(false);

        try {
            const requestBody = {
                "from": params.pagesFrom,
                "to": params.pagesTo,
                "total_offers": totalOffers,
                "country": params.country.length === 0 ? "all" : params.country[0].split(' '),
                "multiple_geos": params.includeMultipleGeos,
                "multiple_geo_as_global": params.specGeoForMultiple,
                "workspace_id": params.workspaceId,
                "blog_ref": params.blogrefNoref,
                "include_column_names": params.header,
                "include_domain": !params.diffCol ? false : params.diffCol,
                "where": JSON.stringify(urlSearchParams)
            };


            const response = await fetch(`${localStorage.getItem('apiUrl')}api/affiliate-network/download-offers-voluum-format/${anAccount}/${userId}`, {

                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localToken}`
                },
                body: JSON.stringify(requestBody)
            });

            checkSession(response.status);

            if (!response.ok) {
                throw new Error('Error fetching data');
            }

            const data = await response.text();
            const rows = data.split('\n').map(row => row.trim());
            const headers = rows[0].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/).map(header => header.trim().replace(/['"]+/g, ''));
            const parsedData = rows.slice(1).map(row => {
                const values = row.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/).map(value => {
                    value = value.trim();
                    if (value.startsWith('"') && value.endsWith('"')) {
                        value = value.slice(1, -1);
                    }
                    return value;
                });
                const obj = {};
                headers.forEach((header, index) => {
                    obj[header] = values[index] || "";
                });
                return obj;
            });

            const headersDataFromEndpoint = headers.map(header => ({
                label: header,
                key: header
            }));

            setCsvData(parsedData);
            setHeadersData(headersDataFromEndpoint);
            setDataReady(true);
        } catch (error) {
            console.error('Error:', error);
            setConfirmationPopupText("Something went wrong")
            setShowConfirmationPopup(true)
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="csv-btn-wrap">
            <button
                onClick={handleDownload}
                className={`${totalOffers === 0 || formError ? "blue-btn disabled" : "blue-btn"}`}
                disabled={totalOffers === 0 || loading || formError}
            >
                {loading ? 'Loading csv...' : 'Get Csv Data'}
            </button>
            {loading && <Loader />}
            {dataReady && (
                <CSVLink
                    data={csvData}
                    filename={filename}
                    className='blue-btn csv'
                    target='_blank'
                    separator={separator}
                    enclosingCharacter={enclosingCharacter}
                    headers={headersData}
                >
                    Download Csv
                </CSVLink>
            )}
        </div>
    );
};

export default CsvDownloadNewFormat;