export const getCampaigns = async (serviceId, type, urlParams, token, dateFrom, dateTo) => {
    const queryParams = new URLSearchParams({
        dateFrom: dateFrom,
        dateTo: dateTo
    }).toString();


    try {
        const response = await fetch(`${localStorage.getItem("apiUrl")}api/voluum/changed-campaigns/${type}/${serviceId}/?${urlParams}&${queryParams}`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
        });

        if (response.status === 200) {
            const data = await response.json();
            return data;
        } else if (response.status === 422) {
            const data = await response.json();
            throw new Error(data["dateTo"]);
        } else {
            const data = await response.json();
            throw new Error(data.message);
        }
    } catch (error) {
        console.error('Error fetching campaigns:', error);
        throw error;
    }
};


export const getCampaignById = async (serviceId, offerId, token, dateFrom, dateTo) => {
    const queryParams = new URLSearchParams({
        dateFrom: dateFrom,
        dateTo: dateTo
    }).toString();

    try {
        const response = await fetch(`${localStorage.getItem("apiUrl")}api/voluum/changed-campaigns-by-id/${serviceId}/${offerId}/?${queryParams}`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching campaigns:', error);
        throw error;
    }
};

export const campaignsDownloadExcel = async (serviceId, dateFrom, dateTo, token) => {
    const queryParams = new URLSearchParams({
        dateFrom: dateFrom,
        dateTo: dateTo
    }).toString();

    try {
        const response = await fetch(`${localStorage.getItem("apiUrl")}api/voluum/changed-campaigns-download/${serviceId}/?${queryParams}`, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching campaigns:', error);
        throw error;
    }
};

export const campaignsDownloadExcelById = async (serviceId, offerId, dateFrom, dateTo, token) => {
    const queryParams = new URLSearchParams({
        dateFrom: dateFrom,
        dateTo: dateTo
    }).toString();

    try {
        const response = await fetch(`${localStorage.getItem("apiUrl")}api/voluum/changed-campaigns-by-id-download/${serviceId}/${offerId}/?${queryParams}`, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching campaigns:', error);
        throw error;
    }
};