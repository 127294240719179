export const getAnOfferCountries = async () => {
    const token = localStorage.getItem("auth_token");

    return await fetch(`${localStorage.getItem('apiUrl')}api/countries`, {
        headers: {
            method: 'GET',
            'Authorization': `Bearer ${token}`,
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
    })
        .then(res => {
            if (res.ok) {
                return res.json();
            }
            return Promise.reject(res)
        })
        .then(res => {
            return res
        })
        .catch(e => console.error(e))
}
