import React, { useEffect, useState } from "react";
import Select from "react-select";
import DataTable from "../../components/tables/DataTable";
import Loader from "../../components/loaders/Loader";
import Pagination from "../../components/Pagination";
import AffiliateNetworkOffersTableFilter from "../../components/AffiliateNetworkOffersTableFilter";
import {
    orderDirectionOptions,
    rowsLimitOptions,
} from "./table-options";
import "./postback-log.scss";
import MessageSendingData from "../../components/popups/MessageSendingData";
import CsvDownload from "../../components/buttons/CsvDownload";
import OpenPopupBtn from "../../components/buttons/OpenPopupBtn";
import ClearFilters from "../../components/buttons/ClearFilters";
import TableLoader from "../../components/loaders/TableLoader";
import CustomOpenSelect from "../../components/CustomOpenSelect";
import { useSelector } from "react-redux";
import CSVinVoluumFormatPopup from "../../components/popups/CSVinVoluumFormatPopup";
import prepareCSVData from "../../helpers/prepare-csv-data";
import prepareVoluumFormatCSVData from "../../helpers/prepare-voluum-format-csv-data";
import { UPLOADER_TYPE_OFFERS } from "../../constants";
import CSVinVoluumFormatPopupMultipleSteps from "../../components/popups/CSVinVoluumFormatPopupMultipleSteps";
import { USER_ROLE } from "../../constants/Auth";
import {AUTH_TOKEN} from "../../constants/Auth";
import {logOut} from "../../redux/dataUploader/dataSlice";
import {useDispatch} from "react-redux";

const PostbackLog = () => {
    const role = localStorage.getItem(USER_ROLE);
    const [isLoading, setIsLoading] = useState(false);
    const [restOffersData, setRestOffersData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const [rowsLimit, setRowsLimit] = useState({ value: "10", label: "10 / page" });
    const [totalRows, setTotalRows] = useState(0);
    const [totalPagesCount, setTotalPagesCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedFilters, setSelectedFilters] = useState({
        orderDirection: orderDirectionOptions[0],
        searchInColumns: {},
    });
    const [orderOptions, setOrderOptions] = useState([]);
    const [selectedUser, setSelectedUser] = useState({
        value: localStorage.getItem("id"),
        label: localStorage.getItem("name"),
    });
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [confirmationPopupText, setConfirmationPopupText] = useState("");
    const [usersList, setUsersList] = useState([]);
    const [anTypes, setAnTypes] = useState([]);
    const [services, setServices] = useState([])
    const [anAccounts, setAnAccounts] = useState([]);
    const [selectedANAccount, setSelectedANAccount] = useState(null);
    const [accountId, setAccountId] = useState(null);
    const loggedUserEmail = localStorage.getItem("email");

    const [parsedOffersData, setParsedOffersData] = useState([]);
    const [searchByColumnFields, setSearchByColumnFields] = useState([]);
    const [filterParams, setFilterParams] = useState({});
    let [tags, setTags] = useState([]);
    const dispatch = useDispatch();

    const checkSession = (status) => {
        if(status === 401) {
            dispatch(logOut());
        }
    }
    
    const localToken = localStorage.getItem(AUTH_TOKEN);

    const selectStyles = {
        container: (baseStyles) => ({
            ...baseStyles,
            maxWidth: "180px",
            width: "100%",
            margin: "0 20px 0 0",
        }),
        control: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: "14px",
            lineHeight: "1.2",
            minHeight: "26px",
            border: "none",
            borderRadius: "8px",
            "&:hover": {
                cursor: "pointer",
            },
        }),
        placeholder: (baseStyles) => ({
            ...baseStyles,
            fontWeight: 500,
            color: "#1A3353",
        }),
        menu: (baseStyles, state) => ({
            ...baseStyles,
            zIndex: "6",
            "&:hover": {
                cursor: "pointer",
            },
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: "14px",
            lineHeight: "1.2",
            "&:hover": {
                cursor: "pointer",
            },
        }),
    };

    const rowsLimitSelectStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: "14px",
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: "14px",
        }),
    };

    useEffect(() => {
        if (totalRows > 0) {
            setTotalPagesCount(Math.ceil(totalRows / rowsLimit.value));
        } else {
            setTotalPagesCount(1);
        }
        setCurrentPage(1);
    }, [totalRows, rowsLimit]);

    useEffect(() => {
        setIsLoading(true);

        // initial fetch
        fetch(`${localStorage.getItem("apiUrl")}api/user/${selectedUser.value}/services/`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localToken}`
            },
        })
            .then(res => {
                checkSession(res.status);
                if (res.ok) {
                    return res.json()
                }
                return Promise.reject(res)
            })
            .then(res => {
                setUsersList([...res["user_managers_accounts"]])
                setIsLoading(false)
            })
            .catch(res => {
                setIsLoading(false)
            })
    }, []);

    useEffect(() => {
        if (role !== 'admin') {
            fetch(`${localStorage.getItem("apiUrl")}api/user/${selectedUser.value}/services`, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${localToken}`
                },
            })
                .then(res => {
                    checkSession(res.status);
                    if (res.ok) {
                        return res.json()
                    }
                    return Promise.reject(res)
                })
                .then(res => {

                    if (res.user_services.length > 0) {


                        let temp = []
                        let services = []

                        for (let service of res.user_services) {
                            temp.push({
                                value: service.id,
                                label: service.name
                            })
                            services.push(service.id)
                        }

                        if (temp.length > 0) {
                            setAnAccounts(temp)
                        }

                        if (services.length > 0) {
                            setAccountId(services[0])
                            setServices(services)
                        }
                    } else {
                        console.error("error")
                    }


                })
                .catch(err => {
                    setIsLoading(false);
                    console.error(err)
                })
        }
    }, []);

    /* fetch offer_directions */
    useEffect(() => {
        if (restOffersData) {
            setIsLoading(true);

            let urlOrderParam = "";
            if (selectedFilters.orderDirection && selectedFilters.orderBy) {
                urlOrderParam = `&offerSortColumn=${selectedFilters.orderBy.value}&offerSortDirection=${selectedFilters.orderDirection.value}`;
            }

            let urlColumnsToSearchParam = {};
            if (filterParams) {
                urlColumnsToSearchParam = { ...filterParams };
            }

            let urlParams = `
            ?size=${rowsLimit.value}
            &page=${currentPage}
            ${urlOrderParam}
            &email=${loggedUserEmail}
            &where=${encodeURI(JSON.stringify(urlColumnsToSearchParam))}
        `   .replace(/\s{2,}|\n+/gm, ""); // Regexp removes more than two whitespaces one by one and linebreaks

            // Fetch offers on filters change
            if (selectedANAccount) {
                fetch(`${localStorage.getItem("apiUrl")}api/postbacks/logs/${selectedANAccount.value}${urlParams}`, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${localToken}`
                    },
                })
                    .then(res => {
                        checkSession(res.status);
                        if (!res.ok) {
                            console.error(`Fetch error: ${res.status} ${res.statusText}`);
                            return Promise.reject(res);
                        } else {
                            return res.json();
                        }
                    })
                    .then(res => {
                        setRestOffersData(res);
                        setIsLoading(false);
                    })
                    .catch(error => {
                        console.error('Catch block error:', error);
                        setIsLoading(false);
                        setConfirmationPopupText("Something went wrong");
                        setShowConfirmationPopup(true);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    })
            }
        }
    }, [rowsLimit, currentPage, selectedFilters, filterParams, tags]);

    useEffect(() => {
        if (restOffersData.data) {
            const temp = [];
            if (restOffersData.data.length !== 0) {
                const keys = Object.keys(restOffersData.data[0]).filter(key => key !== 'id');

                for (let key of keys) {
                    const width = "20%";
                    temp.push({
                        label: key.replace("_", " "),
                        renderCell: (item) => (
                            <span
                                className="data-table-cell"
                                title="Click to copy"
                                onClick={() => handleClickToCopy(item[key])}
                            >
                            {item[key]}
                        </span>
                        ),
                        resize: true,
                        key: key,
                        width: width,
                    });
                }
            }
            setColumnDefs(temp);
            setOrderOptions(temp.map(item => ({ value: item.key, label: item.key.replace("_", " ") })));
            setTotalRows(restOffersData.meta["total"]);
            setTotalPagesCount(restOffersData.meta["last_page"]);
        }
    }, [restOffersData]);


    useEffect(() => {
        parsingArrayFields();
    }, [restOffersData]);

    const parsingArrayFields = (offers = restOffersData.data) => {
        const currentOffers = offers;
        const correctOffers = [];
        if (currentOffers && offers.length) {
            for (let offer of currentOffers) {
                correctOffers.push({
                    ...offer,
                });
            }
            setParsedOffersData(correctOffers);
        }
    };

    const handleGettingOfferWithSearchParams = async (filters = {}) => {
        setIsLoading(true);

        let urlOrderParam = ""
        if (selectedFilters.orderDirection && selectedFilters.orderBy) {
            urlOrderParam = `&offerSortColumn=${selectedFilters.orderBy.value}&offerSortDirection=${selectedFilters.orderDirection.value}`
        }


        let urlColumnsToSearchParam = {};
        if (filterParams) {
            urlColumnsToSearchParam = {...filters};
        }

        let urlParams = `
                ?size=${rowsLimit.value}
                &page=${currentPage}
                ${urlOrderParam}
                &email=${loggedUserEmail}
                &where=${encodeURI(JSON.stringify(urlColumnsToSearchParam))}
            `.replace(/\s{2,}|\n+/gm, ""); //regexp removes more than two whitespaces one by one and linebreaks


        fetch(`${localStorage.getItem("apiUrl")}api/postbacks/logs/${selectedANAccount.value}${urlParams}`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localToken}`
            },
        })
            .then(res => {
                checkSession(res.status);
                if (!res.ok) {
                    return Promise.reject(res)
                } else {
                    return res.json()
                }
            })
            .then(res => {
                setTotalRows(res.meta.total);
                setParsedOffersData(res.data);
                parsingArrayFields(res.data);
            })
            .catch(e => console.error(e))
            .finally(() => {
                setIsLoading(false);
            })


        setIsLoading(false);
    }

    const handleChangeSelectedUser = (user) => {
        setIsLoading(true);
        setSelectedANAccount(null);

        // fetch user services
        fetch(`${localStorage.getItem("apiUrl")}api/user/${user.value}/services`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localToken}`
            },
        })
            .then((res) => {
                checkSession(res.status);
                if (!res.ok) {
                    return Promise.reject(res);
                } else {
                    return res.json();
                }
            })
            .then((res) => {
                setSelectedUser({
                    value: res.id,
                    label: res.name,
                });

                if (res.user_services.length > 0) {
                    let services = [];
                    let temp = [];

                    for (let service of res.user_services) {
                        services.push(Number(service.id));
                        temp.push({
                            value: service.id,
                            label: service.name,
                        });
                    }

                    if (temp.length > 0) {
                        setAnAccounts(temp);
                    }
                } else {
                    console.error("The user doesn't have any service");
                }

                setIsLoading(false);
            })
            .catch((res) => {
                setIsLoading(false);
                setConfirmationPopupText("Failed to fetch user services.");
                setShowConfirmationPopup(true);
            })
            .finally(() => {
                setIsLoading(false);
            })
    };

    const handleChangeSelectedFilters = (value) => {
        setSelectedFilters(value)
    }

    const handleClearSearchByColumnFilter = () => {
        setIsLoading(true);
        let temp = {
            ...selectedFilters,
            searchInColumns: {}
        };

        setFilterParams({});
        setTags([]);
        setSelectedFilters(temp);
        setIsLoading(false);
    }

    const handleChangeANAccount = (option) => {
        setSelectedANAccount(option);
        setIsLoading(true);

        let filters = {
            ...selectedFilters,
            searchInColumns: {},
        };
        setSelectedFilters(filters);

        const accountID = option.value;
        fetch(`${localStorage.getItem("apiUrl")}api/postbacks/logs/${accountID}`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localToken}`
            },
        })
            .then((res) => {
                checkSession(res.status);
                if (!res.ok) {
                    throw new Error("Network response was not ok");
                }
                return res.json();
            })
            .then((res) => {
                if (res.status !== "error" && res.data.length !== 0) {
                    setRestOffersData(res);
                    setCurrentFilterKeys(res.data[0]);

                    let filters = {
                        ...selectedFilters,
                        searchInColumns: {},
                    };
                    setSelectedFilters(filters);
                } else {
                    setRestOffersData([]);
                    console.error("There are no offers for this account")
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setIsLoading(false);
                setConfirmationPopupText("Failed to fetch postback logs.");
                setShowConfirmationPopup(true);
            })
            .finally(() => {
                setIsLoading(false);
            })
    };

    const setCurrentFilterKeys = (offer) => {
        const filterKeys = Object.keys(offer);
        let filteredKeys = filterKeys.filter(f => f !== "id");
        let filterValuesArr = [];

        for (let filterKey of filteredKeys) {
            const fieldLabel = filterKey.replaceAll("_", " ");
            const fieldType = (filterKey === "created_at"
                || filterKey === "updated_at"
                || filterKey === "last_deactivation_date"
                || filterKey === "last_activation_date") ? "date" : "string";

            filterValuesArr.push({
                value: filterKey,
                label: fieldLabel,
                type: fieldType,
            });
        }

        if (filterValuesArr.length > 0) {
            setSearchByColumnFields(filterValuesArr);
        }
    };



    const handleDownloadCsv = () => {
        setIsLoading(true)

        let urlOrderParam = ""
        if (selectedFilters.orderDirection && selectedFilters.orderBy) {
            urlOrderParam = `offerSortColumn=${selectedFilters.orderBy.value}&offerSortDirection=${selectedFilters.orderDirection.value}`
        }


        let urlColumnsToSearchParam = {};
        if (filterParams) {
            urlColumnsToSearchParam = {...filterParams};
        }


        const urlParams = `
            ${urlOrderParam}
            &email=${loggedUserEmail}
            &where=${encodeURI(JSON.stringify(urlColumnsToSearchParam))}
        `.replace(/\s{2,}|\n+/gm, ""); //regexp removes more than two whitespaces one by one and linebreaks


        fetch(`${localStorage.getItem("apiUrl")}api/postbacks/download-logs/${selectedANAccount.value}/?${urlParams}`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localToken}`
            },
        })
            .then(res => {
                checkSession(res.status);
                if (!res.ok) {
                    return Promise.reject(res)
                }
                return res.json()
            })
            .then(res => {
                setConfirmationPopupText(res.message)
                setShowConfirmationPopup(true)
                setIsLoading(false);
            })
            .catch(res => {
                setIsLoading(false)
                setConfirmationPopupText("Something went wrong")
                setShowConfirmationPopup(true)
            })

    }

    const handleClickToCopy = (value) => {
        navigator.clipboard.writeText(value).then(
            () => {},
            () => {
                console.error("Failed to copy");
            }
        );
    };

    if (isLoading) {
        return (
            <div className="loading-wrap">
                <Loader />
            </div>
        );
    }

    return (
        <>
            <div className="affiliate-network-offers-container">
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "0 0 10px",
                    }}
                >
                    <Select
                        className={""}
                        styles={selectStyles}
                        options={usersList.reduce((acc, item) => {
                            acc.push({value: item.id, label: item.name});
                            return acc;
                        }, [])}
                        isSearchable={true}
                        defaultValue={{
                            value: localStorage.getItem("id"),
                            label: localStorage.getItem("name"),
                        }}
                        name={"user"}
                        placeholder={"User"}
                        components={{
                            IndicatorSeparator: () => null,
                        }}
                        onChange={(option) => handleChangeSelectedUser(option)}
                        value={selectedUser}
                    />

                    <Select
                        className={""}
                        styles={selectStyles}
                        options={anAccounts}
                        isSearchable={true}
                        defaultValue={selectedANAccount}
                        name={"affiliate_network_account"}
                        placeholder={"AN Account"}
                        value={selectedANAccount}
                        components={{
                            IndicatorSeparator: () => null,
                        }}
                        onChange={(option) => handleChangeANAccount(option)}
                    />
                    {/*<button*/}
                    {/*    className={`blue-btn ${!Boolean(restOffersData["data"]) ? "disabled" : ""}`}*/}
                    {/*    onClick={handleDownloadCsv}*/}
                    {/*    disabled={!Boolean(restOffersData["data"])}*/}
                    {/*>*/}
                    {/*    Download CSV - All data*/}
                    {/*</button>*/}
                </div>

                <>
                    <AffiliateNetworkOffersTableFilter
                        orderByOptions={orderOptions}
                        orderDirectionOptions={orderDirectionOptions}
                        searchByColumnOptions={searchByColumnFields}
                        selectedFilters={selectedFilters}
                        handleGettingOfferWithSearchParams={handleGettingOfferWithSearchParams}
                        changeSelectedFilters={handleChangeSelectedFilters}
                        clearSearchFilters={handleClearSearchByColumnFilter}
                        setFilterParams={setFilterParams}
                        filterParams={filterParams}
                        setTags={setTags}
                        tags={tags}
                    />

                    <DataTable data={parsedOffersData} columnDefs={columnDefs} />
                </>

                {restOffersData.data && restOffersData.data.length > 0 && (
                    <div className="data-table-footer">
                        {rowsLimitOptions.length > 0 && (
                            <Select
                                className={"data-table-rows-limit"}
                                styles={rowsLimitSelectStyles}
                                options={rowsLimitOptions}
                                isSearchable={false}
                                defaultValue={rowsLimit}
                                name={"rows_count"}
                                onChange={(option) => {
                                    setRowsLimit(option);
                                }}
                            />
                        )}
                        <div className={"data-table-rows-count"}>
                            Total rows: <strong>{totalRows}</strong>
                        </div>
                        <Pagination
                            totalPagesCount={totalPagesCount}
                            setCurrentPage={(value) => setCurrentPage(value)}
                            currentPage={currentPage}
                        />
                    </div>
                )}
            </div>

            {showConfirmationPopup && (
                <MessageSendingData
                    messageText={confirmationPopupText}
                    setClosePopup={() => setShowConfirmationPopup(false)}
                />
            )}
        </>
    );
};

export default PostbackLog;
